import React from "react";

const Input = ({ value, onChange, label, placeholder, required, ...props }) => {
  return (
    <div className="mb-4">
      <label className="text-body-2" htmlFor={label}>
        {label}
      </label>
      <input
        id={label}
        name={label.toLowerCase()}
        type="text"
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        className="bg-white rounded shadow-sm px-4 py-2 text-base block w-full"
        required={required}
        {...props}
      />
    </div>
  );
};

Input.defaultProps = {
  required: true,
};

export default Input;
