import { graphql, Link } from "gatsby";
import React from "react";
import BlockContent from "../components/BlockContent";
import Button from "../components/Button";
import CookieBanner from "../components/CookieBanner";
import Footer from "../components/Footer";
import Input from "../components/Input";
import Nav from "../components/Nav";

const Register = ({
  data: {
    sanityRegister: {
      title,
      description,
      inputs,
      submit,
      footnote,
      priceExample,
      privacyText,
    },
  },
}) => {
  const onSubmit = (e) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "soknadskjema-innsendt",
    });
  };
  return (
    <>
      <Nav hideLinks />
      <div className="max-w-screen-lg mx-auto pt-top md:px-page px-4">
        <h1 className="text-heading-3 text-center mb-4">{title}</h1>
        <p className="text-body-1 text-center mb-4">{description}</p>
        <form
          name="contact"
          method="post"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          className="flex justify-center flex-col max-w-md mx-auto mb-16"
          action="/innsendt"
          onSubmit={onSubmit}
        >
          <p class="hidden">
            <label>
              Don’t fill this out if you’re human: <input name="bot-field" />
            </label>
          </p>
          <input type="hidden" name="form-name" value="contact" />
          {inputs.map((values) => (
            <Input {...values} />
          ))}
          <Button type="submit">{submit}</Button>
        </form>
      </div>
      <CookieBanner />
    </>
  );
};

export const query = graphql`
  query {
    sanityRegister(_id: { regex: "/register$/" }) {
      id
      title
      description
      inputs {
        label
        placeholder
      }
      privacyText
      submit
      footnote: _rawFootnote
      priceExample
    }
  }
`;

export default Register;
